<script>
    let className = '';
    export { className as class }

	/**
	 * @type {'' | 'calendar' | 'calendar_rest' | 'chart_bar' | 'collection' | 'minus' | 'minus_circle' | 'backspace' | 'pencil_circle' | 'selector' | 'menu' | 'fire' | 'chevron_down' | 'chevron_right' | 'chevron_left' | 'arrow_left' | 'arrow_down_tray' | 'volume_off' | 'volume_up' | 'drag' | 'times' | 'clock' | 'arrow_circle_right' | 'arrow_circle_left' | 'arrow_path' | 'pause' | 'play' | 'plus' | 'plus_circle' | 'check' | 'check_circle' | 'switch_vertical' | 'log_in' | 'log_out' | 'lock_closed' | 'link' | 'loading' | 'google' | 'mail' | 'arrow_up'}
	 */
	export let icon = '';

	export let small_label = false;

	export let xs = false;
    export let sm = false;
    export let lg = false;
    export let xl = false;
	export let xxl = false;
	export let xxxl = false;

	export let spin = false;
	export let spin_slow = false;	

	export let calendar = false;
	export let calendar_rest = false;
	export let chart_bar = false;
	export let collection = false;
	export let minus = false;
	export let minus_circle = false;
	export let backspace = false;
	export let pencil_circle = false;
	export let selector = false;
	export let menu = false;	
	export let fire = false;
	export let chevron_down = false;
	export let chevron_right = false;
	export let chevron_left = false;
	export let arrow_left = false;
	export let arrow_down_tray = false;
	export let volume_off = false;
	export let volume_up = false;
	export let drag = false;
	export let times = false;
	export let clock = false;
	export let arrow_circle_right = false;
	export let arrow_circle_left = false;
	export let arrow_path = false;
	export let pause = false;
	export let play = false;
	export let plus = false; 
	export let plus_circle = false; 
	export let check = false; 
	export let check_circle = false; 
	export let switch_vertical = false; 
	export let log_in = false;
	export let log_out = false;
	export let lock_closed = false;
	export let link = false;
	export let loading = false;
	export let google = false;
	export let mail = false;
	export let arrow_up = false;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<svg
	xmlns="http://www.w3.org/2000/svg"
	class="inline-block
		{
			xs ? 'h-3 w-3' 
			: sm ? 'h-4 w-4'
			: lg ? 'h-8 w-8'
			: xl ? 'h-12 w-12'
			: xxl ? 'h-16 w-16'
			: xxxl ? 'h-24 w-24'
			: 'h-6 w-6'
		}
		{
			spin ? 'animate-spin' : ''
		}
		{
			spin_slow ? 'animate-[spin_2s_infinite]' : ''
		}
		{className}
	"
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
	on:click
>
	{#if icon === 'calendar'  || calendar}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
	{:else if icon === 'calendar_rest'  || calendar_rest}
		<path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M5.28.47A.75.75 0 0 0 4 1v1.5h-.75a3 3 0 0 0-3 3v11.25a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3V5.5a3 3 0 0 0-3-3H16V1a.75.75 0 1 0-1.5 0v1.5h-9V1c0-.2-.08-.39-.22-.53Zm12.53 7.72c.28.28.44.66.44 1.06v7.5a1.5 1.5 0 0 1-1.5 1.5H3.25a1.5 1.5 0 0 1-1.5-1.5v-7.5a1.5 1.5 0 0 1 1.5-1.5h13.5c.4 0 .78.16 1.06.44ZM7.52 15.03v.9h4.95v-1.32H9.62l2.86-3.82v-.92H7.53v1.33h2.86l-2.87 3.83Z"/>
	{:else if icon === 'chart_bar'  || chart_bar}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
	{:else if icon === 'collection'  || collection}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
	{:else if icon === 'minus'  || minus}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
	{:else if icon === 'minus_circle'  || minus_circle}
		<path stroke-linecap="round" stroke-linejoin="round"  stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />		  
	{:else if icon === 'backspace'  || backspace}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
	{:else if icon === 'pencil_circle' || pencil_circle}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
	{:else if icon === 'selector'  || selector}
		<path stroke-linecap="round" stroke-linejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
	{:else if icon === 'menu'  || menu}	
		<path fill-rule="evenodd" fill="currentColor" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
	{:else if icon === 'fire' || fire}	
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
	{:else if icon === 'chevron_left' || chevron_left}
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
	{:else if icon === 'chevron_right' || chevron_right}	
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
	{:else if icon === 'chevron_down' || chevron_down}
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
	{:else if icon === 'arrow_left' || arrow_left}
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
	{:else if icon === 'arrow_down_tray' || arrow_down_tray}
		<path stroke-width="2"  stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
	{:else if icon === 'volume_off' || volume_off}
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" clip-rule="evenodd" />
		<path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
	{:else if icon === 'volume_up' || volume_up}
		<path  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
	{:else if icon === 'drag' || drag}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M24 9H0" />
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M24 15H0" />
	{:else if icon === 'times' || times}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
	{:else if icon === 'clock' || clock}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'arrow_circle_right' || arrow_circle_right}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'arrow_circle_left' || arrow_circle_left}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
	{:else if icon === 'arrow_path' || arrow_path}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />  
	{:else if icon === 'arrow_up' || arrow_up}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke="currentColor" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
	{:else if icon === 'pause' || pause}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'play' || play}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'plus' || plus}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
	{:else if icon === 'plus_circle' || plus_circle}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'check' || check}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
	{:else if icon === 'check_circle' || check_circle}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
	{:else if icon === 'switch_vertical' || switch_vertical}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4" />
	{:else if icon === 'lock_closed' || lock_closed}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />	  
	{:else if icon === 'log_in' || log_in} 
		<path stroke-linecap="round" stroke-linejoin="round"  stroke-width="1.5" stroke="currentColor" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
	{:else if icon === 'log_out' || log_out} 
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" stroke="currentColor" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
	{:else if icon === 'link' || link}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />	  
	{:else if icon === 'loading' || loading}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M2.99994 12C2.99994 16.9706 7.02938 21 11.9999 21C16.9705 21 20.9999 16.9706 20.9999 12C20.9999 7.02943 16.9705 2.99999 11.9999 2.99999C10.6424 2.99999 9.35501 3.30057 8.20077 3.83881" />
	{:else if icon === 'google' || google}
		<path fill="currentColor" d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z"/>
	{:else if icon === 'mail' || mail}
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
	{:else}
		?
	{/if}
</svg>


{#if $$slots.default}
	<span class="{small_label ? 'text-xs block': 'sr-only'}"><slot /></span>
{/if}

	
	